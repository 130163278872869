<template>
  <div class="special-box">
    <div class="bg-color" :style="`background-color:${backgroundColor}`"></div>
    <img class="banner" v-if="banners" :src="requestImgUrl(banners.img)" alt="">
    <div class="warp">
      <div class="nav-box" ref="navBigBox">
        <div class="fixed-box" :class="fixed ? 'fixed' : ''" ref="navBox">
          <div class="category-tag" :class="tagsIndex == 0 ? 'active' : ''" @click="changeTags(ids, 0)">
            <i class="icon-nav-active"></i>
            全部
          </div>
          <div class="category-tag" v-for="(item, index) in tags" :key="index" :class="tagsIndex == index + 1 ? 'active' : ''" v-if="item.categoryCode != tagsCode" @click="changeTags(item.id, index + 1)">
            <i class="icon-nav-active"></i>
            {{item.name}}
          </div>
        </div>
      </div>
      <ul class="commodity-list"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="loading"
      infinite-scroll-immediate-check="false"
      infinite-scroll-distance="10">
        <li v-for="(item, index) in lists" :key="index" @click="$router.push(`/shopping/details?id=${item.id}`)">
          <div class="content">
            <img :key="requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)" alt="" v-lazy="requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)">
            <div class="right-content">
              <div class="name">{{item.name}}</div>
              <p class="ms">{{item.description}}</p>
              <div class="price-box">
                <div class="present-price" v-if="(item.specList[0].unitPrice) > 0">
                  <div class="price-details" v-if="!item.specsShow">
                    <div class="unit">￥</div>
                    <div class="moeny">{{item.specList[0].unitPrice  + item.specList[0].deposit}} <span class="unit">{{item.specList[0].minUnitName != '' ? '/'+item.specList[0].minUnitName : ''}}</span></div>
                    <div class="no-unitName" v-if="item.unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1">(￥{{(Number(item.specList[0].price) + item.specList[0].deposit)}}{{item.specList[0].unitName!=''? '/'+item.specList[0].unitName:(item.unitName != '' ? `/${item.unitName}` : '')}})</div>
                  </div>
                </div>
                <div class="is-certified" v-else>{{isCertified()}}</div>

                <div class="specs-btn-box jb-r-l" v-if="item.specList && item.specList.length > 1" @click.stop="changeSpecs(index)" >{{ item.specsShow ? "收起" : "规格" }}</div>
                <div class="icon-car-btn" v-else @click.stop=" SelectProducts(item.id,item.specList[0].id,item.inventory)"></div>

              </div>
            </div>
          </div>

          <!-- 规格选项 start -->
          <div class="specs-box" :class="item.specsShow ? 'active' : ''" v-if="item.specList && item.specList.length > 1">
            <div class="specs-item" v-for="(item2,index2) in item.specList" :key="index2">
              {{item2.name}}  <span class="deposit" v-if="item2.deposit>0 && item2.price > 0">(含押金{{item2.deposit}}元)</span>
              <div class="is-certified" v-if="item2.price < 0 ">{{isCertified()}}</div>
              <span class="present-price" v-else>
                <span class="unit">￥</span>
                <span class="moeny">{{Number(item2.price) + item2.deposit}}</span>
                <span class="unit">{{item2!=''? '/'+item2.unitName:(item.unitName != "" ? "/"+item.unitName : "")}}</span>
              </span>
              <div class="specs-btn jb-r-l" @click.stop="SelectProducts(item.id, item2.id)">+</div>
            </div>
          </div>
          <!-- 规格选项 end -->

        </li>
        <div class="no-data" v-if="nodata">
          <no-data></no-data>
        </div>
        
      </ul>
    </div>

    <router-link to="/shopping/car" class="gwc-box">
      <img src="@/assets/images/img/gwc-xf.png" alt="">
      <mt-badge v-if=" goodsNum > 0" class="badge" color="#eb3030" size="small">{{goodsNum}}</mt-badge>
    </router-link>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState  } from 'vuex';
import { requestImgUrl, getGoodsImg, CountDown, isWeiXin, DateFormat } from "@/utils/common";
import { isCertified } from "@/utils/utils";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.getGoodsImg = v => getGoodsImg(v);
    this.isCertified = () => isCertified();
    return {
      nomore:false,
      nodata:false,
      loading: false,
      banners: '',
      tagsIndex: 0,
      tags: [],
      lists: [],
      ids: '',
      fixed: false,
      tagsCode: '',
      pages: 1,
      pageNum: 1,
      id: '',
      backgroundColor: '',
    }
  },
  activated() {
    let tagsCode = this.$route.query.tagsCode;
    if (localStorage.getItem('tagsCode') !== tagsCode) {
      this.banners=[];
      this.backgroundColor = "#f5f5f5"
      this.pageNum = 1;
      this.lists = [];
      this.init(tagsCode);
    }
    
  },
  deactivated() {
    localStorage.setItem('tagsCode', this.tagsCode)
  },
  mounted() {
    this.tagsCode = this.$route.query.tagsCode;
    this.init(this.tagsCode);
  },
  methods: {
    // 显示规格列表
    changeSpecs(index) {
      let commodity = this.lists[index];
      commodity.specsShow = !commodity.specsShow;
      this.$set(this.lists, index, commodity);
    },
    init(tagsCode) {
      this.tagsCode = tagsCode;
      document.title = this.$route.query.title;
      this.getAd({
        positionCode: tagsCode
      }).then((res)=> {
        this.banners = res.data[0];
        this.backgroundColor = res.data.length > 0 ? res.data[0].colorGradientStart : '#f5f5f5'
      });

      this.CommodityClass({
        level: 1,
        tagsCode: tagsCode
      }).then((res)=> {
        this.tags = res.data;
        window.addEventListener('scroll',this.handleScroll)
        this.getList('')
      })
    },
    // 分页
    loadMore() {
      this.loading = true;
      let pageNum = this.pageNum + 1;
      if (pageNum > this.pages) {
        this.nomore = true;
      } else {
        this.pageNum = pageNum;
        this.getList(this.id, 'add')
      }
    },
    // 页面滚动
    handleScroll(e) {
      let offsetTop = this.$refs.navBigBox.offsetTop;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      if ((scrollTop) >= offsetTop) {
        this.fixed = true;
      } else {
        this.fixed = false;
      }
    },
    changeTags(id, index) {
      this.pageNum = 1;
      this.tagsIndex = index;
      this.getList(id);
      this.$nextTick(function() {
        let width = this.$refs.navBox.clientWidth / 2;
        let offsetLeft = this.$refs.navBox.children[index].offsetLeft;
        if (offsetLeft < width) {
          this.$refs.navBox.scrollLeft = '0';
        } else {
          this.$refs.navBox.scrollLeft = offsetLeft - width;
        }
      })
    },
    getList(id, add) {
      this.id = id;
      this.GetCommodityList({
        categoryFirst: id,
        pageNum: this.pageNum,
        tagsCode: this.tagsCode,
      }).then((res)=> {
        if (add) {
          this.lists = this.lists.concat(res.data.list);
          this.loading = false;
        } else {
          this.lists = res.data.list;
          this.pages = res.data.pages;
        }
        this.pageNum = res.data.pageNum;
        this.nodata = this.lists.length > 0 ? false : true;
      })
    },
    // 选择商品事件
    SelectProducts(commodityId, specId, inventory) {
      if(inventory <= 0) {
        this.$toast("卖光啦！");
        return false;
      }
      this.AddCommodityCart({
        commodityId: commodityId,
        specId: specId,
        uid: this.uid,
      }).then((res)=> {
        if (res.status == 200) {
          this.$toast("加入购物车成功")
          // 获取购物车总数
          this.getCommodityCount({
            uid: this.uid
          }).then((res)=> {
            this.goodsNumMuta(res.data.quantity)
          })
        }
      })
    },
    ...mapMutations(["goodsNumMuta"]),
    ...mapActions(['getAd']),
    ...mapActions('shopping', [
      'CommodityClass',
      'GetCommodityList',
      'getCommodityCount',
      'AddCommodityCart',
    ])
  },
  computed: {
    ...mapState(["orgInfo", "goodsNum"]),
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>
<style lang='less' scoped>
.bg-color{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.no-unitName{
  padding-left: .2rem;
  font-size: .24rem;
  color: #ccc;
  line-height: 1.4;
}
.warp{
  padding-top: .2rem;
}
.no-data{
  background: #fff;
  border-radius: .2rem;
  margin-top: .2rem;
}
.gwc-box{
  position: fixed;
  bottom: 6%;
  left: .4rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #ff7141;
  display: flex;
  align-items: center;
  justify-content: center;
  .badge{
    position: absolute;
    top: -0.05rem;
    right: 0;
    font-size: 0.24rem;
    transform: translateX(40%);
  }
  img{
    width: 55%;
  }
}
.price-box{
  margin-top: .15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .present-price{
    display: flex;
    align-items: flex-end;
    color: #ff7241;
    .price-details{
      display: flex;
      align-items: center;
    }
    .unit{
      font-size: 0.24rem;
    }
    .moeny{
      font-size: 0.32rem;
    }
  }
}
.commodity-list{
  li{
    background: #fff;
    border-radius: .2rem;
    margin: .2rem 0;
    padding: .3rem .2rem;
  }
  .content{
    display: flex;
    .right-content{
      flex: 1;
      .name{
        font-size: .3rem;
        color: #333;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .ms{
        font-size: .22rem;
        color: #999;
        margin-top: .15rem;
      }
    }
    img{
      width: 1.6rem;
      height: 1.6rem;
      margin-right: .2rem;
    }
  }
}
.nav-box{
  height: .9rem;
  .fixed-box{
    height: .9rem;
    font-size: .3rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-x: scroll;
    background: #fff;
    border-radius: .2rem;
    &.fixed{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 0;
      box-shadow: 0 0 .1rem #ccc;
    }
    &::-webkit-scrollbar {display:none}
  }
  .active{
    color: #fd7621;
    font-weight: bold;
  }
  .category-tag{
    padding: 0 .2rem;
    display: flex;
    align-items: center;
    white-space:nowrap;
    &.active{
      i{
        display: block;
      }
    }
    i{
      margin-right: .1rem;
      display: none;
    }
  }
}
.special-box{
  padding-bottom: .2rem;
}
.specs-box{
  max-height: 0;
  overflow: hidden;
  &.active{
    max-height: 5rem;
    margin-top: .26rem;
  }
}
.specs-btn-box{
  color: #fff;
  font-size: .24rem;
  padding: .1rem;
  border-radius: .1rem;
  display: flex;
}
.specs-item{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: .28rem;
  height: .8rem;
  padding: 0 .25rem;
  .is-certified{
    margin-top: 0rem;
    margin-left: .05rem;
    font-size: .24rem;
    line-height: 1.2;
  }

  .deposit{
    color: #ccc;
    font-size: .24rem;
    margin-left: .15rem;
  }
  .present-price{
    margin-left: .1rem;
    color: #ff7241;
    font-size: 0.32rem;
    flex-direction:column;
    .unit{
      font-size: 0.24rem;
    }
  }
  .specs-btn{
    height: .48rem;
    width: .48rem;
    border-radius: 50%;
    text-align: center;
    line-height: .48rem;
    color: #fff;
    margin-left: .2rem;
  }
}
</style>