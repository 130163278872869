import { render, staticRenderFns } from "./Special.vue?vue&type=template&id=5c0187d2&scoped=true&"
import script from "./Special.vue?vue&type=script&lang=js&"
export * from "./Special.vue?vue&type=script&lang=js&"
import style0 from "./Special.vue?vue&type=style&index=0&id=5c0187d2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0187d2",
  null
  
)

export default component.exports